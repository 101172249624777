import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs';
import { UserService } from './user.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SystemVersionService {
  versionStorageKey = 'AUDIT_VERSION';

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private router: Router,
  ) {}

  getFromServer() {
    return this.http
      .get<{
        version: string;
      }>(`${environment.auditBackendURL}getCurrentVersion`)
      .pipe(
        map(({ version }) => {
          const versions = this.updateCachedVersion(version);
          return versions;
        }),
      );
  }

  compareAndHandleVersionMismatch() {
    this.getFromServer().subscribe((version) => {
      if (version.current !== version.last) {
        this.reloadCurrentPageWithClearSiteData();
      }
    });
  }

  private updateCachedVersion(newVersion: string) {
    const version = this.getCachedVersion();

    version.last = version.current ?? newVersion;
    version.current = newVersion;

    window.localStorage.setItem(
      this.versionStorageKey,
      JSON.stringify(version),
    );

    return version;
  }

  private getCachedVersion() {
    const version: { last?: string; current?: string } = JSON.parse(
      window.localStorage.getItem(this.versionStorageKey) ?? '{}',
    );
    return version;
  }

  private clearCachedVersion() {
    window.localStorage.removeItem(this.versionStorageKey);
  }

  private reloadCurrentPageWithClearSiteData(): void {
    this.clearCachedVersion();

    this.userService.removeCache();
    this.userService.login(true).subscribe(() => {
      this.router.navigateByUrl(window.location.pathname, {
        onSameUrlNavigation: 'reload',
      });
    });
  }
}
