import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterOutlet, Event } from '@angular/router';
import { ComponentsModule } from './ui/components/components.module';
import { MenuItem } from './ui/models/menu-item';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './ui/services/auth.service';
import { UserService } from './api/services/user.service';
import { MsalListenersConfig } from './configuration/msal-listeners.config';
import { FunctionalityService } from './api/services/functionality.service';
import { AccessLevel, FunctionalityName } from './api/models/functionality';
import { SystemVersionService } from './api/services/system-version.service';

@Component({
  selector: 'app-root',
  imports: [CommonModule, RouterOutlet, ComponentsModule],
  providers: [
    {
      provide: 'Window',
      useValue: window,
    },
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent
  extends MsalListenersConfig
  implements OnInit, OnDestroy
{
  constructor(
    private translate: TranslateService,
    msalService: MsalService,
    msalBroadcastService: MsalBroadcastService,
    authService: AuthService,
    userService: UserService,
    functionalityService: FunctionalityService,
    systemVersion: SystemVersionService,
    @Inject('Window') window: Window,
    private router: Router
  ) {
    super(
      msalService,
      msalBroadcastService,
      authService,
      userService,
      functionalityService,
      window,
      systemVersion,
    );
    this.setupI18n();
  }

  isLogin = this.router.url === '/login' || this.router.url === '/';

  menuItems: MenuItem[] = [
    {
      route: '/user-administration',
      label: 'MENU.ADMIN',
      permissionsRequired: [
        {
          identifier: FunctionalityName.GRANT_PORTAL_ACCESS,
          level: AccessLevel.CREATE,
        },
      ],
    },
    {
      route: '/management',
      label: 'MENU.MANAGEMENT',
      permissionsRequired: [
        {
          identifier: FunctionalityName.VIEW_PROMOS_WITH_GRANTED_ACCESS,
          level: AccessLevel.READ,
        },
        {
          identifier: FunctionalityName.GRANT_VIEWING_PROMO_ACCESS,
          level: AccessLevel.UPDATE,
        },
      ],
    },
    {
      route: '/promotions',
      label: 'MENU.PROMOTIONS',
    },
  ];

  ngOnInit(): void {
    super.initMsal();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.isLogin = event.url === '/login' || event.url === '/';
      }
    });
  }

  async setupI18n() {
    this.translate.addLangs(['pt', 'en']);
    const browserLang = this.translate.getBrowserLang();
    const defaultLanguage = browserLang?.match(/pt|en/)
      ? browserLang
      : this.translate.getDefaultLang();

    return new Promise((resolve) => {
      this.translate.use(defaultLanguage).subscribe((e) => {
        resolve(e);
      });
    });
  }

  ngOnDestroy(): void {
    super.destroyMsal();
  }
}
