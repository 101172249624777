import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { AuthService } from '../../ui/services/auth.service';

@Injectable()
export class SessionExpiredInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
  ) {}

  private findRefreshTokenExpiration() {
    const allItems = Object.keys({ ...window.localStorage });

    for (let index = 0; index < allItems.length; index++) {
      const key = allItems[index];
      try {
        const current = JSON.parse(window.localStorage[key] ?? '');
        if (
          current.credentialType &&
          current.credentialType === 'RefreshToken'
        ) {
          return new Date(current.expiresOn * 1000);
        }
      } catch {
        continue;
      }
    }

    return null;
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const expirationDate = this.findRefreshTokenExpiration();

    if (expirationDate !== null) {
      const now = new Date();

      if (now >= expirationDate) {
        this.authService.clearAllAuthenticationCache();
        window.location.href = '/session-expired';
        return EMPTY;
      }
    }

    return next.handle(req);
  }
}
